import React from "react";
import CmpPayment from "../components/Cmp/CmpPayment";
import Layout from '../components/layout'

const CmpCategory =() =>{
      return(
        <Layout>
         <CmpPayment />
        </Layout>
      )
}

export default CmpCategory