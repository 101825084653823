import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import MDEditor from '@uiw/react-md-editor';
import './cmp.css'

const CmpPayment =() =>{
    const data = useStaticQuery(graphql`
    {
      allStrapiCmps {
        nodes {
          cmp_bg
          Member {
            Amount
            Options
          }
          NonMember {
            Options
            Amount
          }
          Title {
            TitleName
            TitleFontWeight
            TitleFontSize
            TitleFontFamily
            TitleFontColor
            TitleAlignment
          }
        }
      }
    }
  `)   

  var cmp = data.allStrapiCmps.nodes
  var cmpmember = cmp[0].Member
  var cmpnonmember = cmp[0].NonMember
  var cmptitle = cmp[0].Title


  let member = cmpmember.map(element=>{
    return(
      <option value={element.Amount}>{element.Options}</option>
    )
  })

  let nonmember = cmpnonmember.map(element=>{
    return(
      <option value={element.Amount}>{element.Options}</option>
    )
  })

  let title = cmptitle.map(element=>{
    return(
      <h1 style = {{paddingBottom: "30px", textAlign: element.TitleAlignment, color:element.TitleFontColor,fontFamily:element.TitleFontFamily,fontSize:element.TitleFontSize,fontWeight:element.TitleFontWeight}}>{element.TitleName} </h1>
    )
  })

  function Member(){
    var SelectedPrice = document.getElementById("member").value;
    console.log(SelectedPrice)
    sessionStorage.setItem("selectedPrice",SelectedPrice);
    sessionStorage.setItem('Member', 'Member')
    window.location.pathname="/paymentDetails";
   }

   function NonMember(){
    var SelectedPrice = document.getElementById("nonmember").value;
    console.log(SelectedPrice)
    sessionStorage.setItem("selectedPrice",SelectedPrice);
    sessionStorage.setItem('NonMember', 'NonMember')
    window.location.pathname="/paymentDetails";
   }

   return(
       <div className="card cmp_card" style={{backgroundColor:data.allStrapiCmps.nodes[0].cmp_bg}}>
      <div style={{textAlign: "center"}}>
        {title}
      </div>
    <div class="row">
        <div class="column">
            <div >
            <h3 class="categoryTitle" style={{marginBottom:"30px"}}>Member</h3>
            <label ></label> 
            <select style={{marginBottom:"25px"}} class="form-control"  id="member">{member}</select>
            <div style={{textAlign: "center"}}>
            <button type="submit"  class="btn btn-success" onClick={Member}
            style={{backgroundColor:"rgb(0, 128, 0)",
            borderRadius:"7px",
            fontSize:"16px",
            }}>PAY NOW</button>
            </div>
            </div>
        </div>

        <div class="column">
            <div >
            <h3 class="categoryTitle" style={{marginBottom:"30px"}}>Non Member</h3>
            <label></label> 
            <select style={{marginBottom:"25px"}} class="form-control"  id="nonmember">{nonmember}</select>
            <div style={{textAlign: "center"}}>
            <button type="submit"  class="btn btn-success" onClick={NonMember}
            style={{backgroundColor:"rgb(0, 128, 0)",
            borderRadius:"7px",
            fontSize:"16px",
            }}>PAY NOW</button>
            </div>
            </div>
        </div>
        
        
    </div>
       </div>
   )
}

export default CmpPayment
